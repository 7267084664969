import * as React from 'react';

import { Layout } from '../components/layout';

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="Homepage">
      <div>
        <div className="pt3">Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
      </div>
    </Layout>
  )
}

export default IndexPage
