import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '18px',
  headerFontFamily: ['Source Sans Pro', 'sans-serif'],
  bodyFontFamily: ['Source Sans Pro', 'sans-serif'],
});


const { rhythm, scale } = typography;
export { rhythm, scale, typography as default };